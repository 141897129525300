class SizeGuide extends HTMLElement {
    constructor() {
      super();
      this.images = this.querySelectorAll('table');
      this.inches = this.querySelector('.inches');
      this.cm = this.querySelector('.cm');

      console.log(this.images)
      this.cm.addEventListener('click', this.toggle.bind(this));
      this.inches.addEventListener('click', this.toggle.bind(this));
    }

    toggle() {
        this.inches.classList.toggle('underline')
        this.cm.classList.toggle('underline')
        this.images.forEach(image => {
            image.parentElement.classList.toggle('block');
            image.parentElement.classList.toggle('hidden');
        })
      }
    
  }
  
  customElements.define('size-guide', SizeGuide);
  